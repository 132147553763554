import styled from 'styled-components';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../../consts/screen';

interface StyledContainerProps {
  hasAppHeader: boolean;
  hasBreadcrumb: boolean;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ hasAppHeader }) => {
    if (hasAppHeader) return '0 1.5rem 24px 1.5rem';
    else return '24px 1.5rem 24px 1.5rem';
  }};
`;
