import { Rules } from '@hexa-ui/icons';
import { SidebarItem, useSidebar } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { SidebarStyles } from './SideBar.styles';

export const SideBarIcons = {
  Rules: (): JSX.Element => <Rules />,
};

export const SideBar: React.FC = () => {
  const { formatMessage } = useIntl();

  const sideBarItems: SidebarItem[] = [
    {
      id: 'rules',
      title: formatMessage({ id: 'title.sideBar.rulesManagement' }),
      icon: SideBarIcons.Rules,
      path: '/vision/rules',
    },
  ];

  useSidebar({
    items: sideBarItems,
    utils: [],
  });

  return <SidebarStyles />;
};
